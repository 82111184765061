import amarantNews from "../assets/amarant-news-2.png";
import amarant from "../assets/amarant-logo.svg";
import wink from "../assets/wink-logo.svg";
import immotech from "../assets/immotech-logo.svg";
import fightscout from "../assets/fightscout-logo.svg";
import immotechNews from "../assets/immotech.png";
import HolidaysNews from "../assets/2022.png";
import fightScoutNew from "../assets/fightscout-web.png";
import gfl from "../assets/gfl-web.png";
import proakt from "../assets/proakt-web.png";
import fightScoutCombine from "../assets/fightscout-web-new.png";
import HolidayPicture from "../assets/3-march-web.png";
import fantastico from "../assets/fidweb-fantastico_optimized.png";
import fightScoutCommunication from "../assets/fidweb-article-fight-scout-updates_optimized.png";
import fightScoutWebsite from "../assets/fidweb-article-fight-scout-new-website.png";
import fightScoutBKFC from "../assets/fightscout_bkfc.png";
import winkUpdate from "../assets/fidweb-wink-updates_optimized.png";
import testMyStressLogo from "../assets/Practical-stress-logo.svg";
import testMyStressLogoNew from "../assets/test-my-stress-logo-new.svg";
import testMyStress from "../assets/fidweb-practicle-stress_optimized.png";
import testMyStressNew from "../assets/fidweb-testmystress-coming-soon.png";
// import mobiWashLogo from "../assets/Mobi-Wash.svg";
import mobiWashLogo from "../assets/mobiwash.png";
import mobiWash from "../assets/fidweb-mobiwash_optimized.png";
import immotechGermany from "../assets/fidweb-immotech-germany_optimized.png";
import enrpi from "../assets/fidweb-enrpi-new-client_optimized.png";
import winkNews from "../assets/fidweb-wink-articles_optimized.png";
import alpiLogo from "../assets/alpi-logo.svg";
import alpi from "../assets/fidweb-alpi-article_optimized.png";
import amarant3 from "../assets/amarant-3.png";
import scoutify from "../assets/Scoutify-App-Article-Image.png";
import sportforia from '../assets/Sportforia_News_Picture.png';
import wsEnergy from '../assets/WSEnergy_News_Picture.png';

const News = [
  {
    resize: true,
    logo: false,
    image: wsEnergy,
    title: "Fidweb Delivers Comprehensive Project Management Solution for WS Energy",
    html: true,
    id: "7dbd4bf243094f3c9bdf41432789ggwfed",
    // apple: "https://apps.apple.com/bg/app/scoutify-app/id6514315275",
    // play: "https://play.google.com/store/apps/details?id=net.fidweb.scoutify",
    images: [],
    parts: [
      "<p className='no-padding medium'>Fidweb is proud to announce the successful implementation of its innovative project management system, Fidweb Intranet, for WS Energy. The custom-designed platform streamlines workflow processes, enhances team collaboration, and optimizes resource management across the organization.</p>",
      "<p className='no-padding medium'>The Fidweb Intranet solution addresses WS Energy's need for a centralized management system that integrates key business functions into a unified digital workspace. By consolidating task management, document flow, time-off tracking, calendaring, and internal communications, the platform enables more efficient operations and improved productivity. Fidweb worked closely with WS Energy to develop a tailored solution that transforms how they manage projects and internal processes.</p>",
      "<p className='no-padding medium'>The Fidweb Intranet platform serves as a comprehensive tool that solves real business challenges through its intuitive design and integrated functionality.</p>",
      "<div className='flex flex-col no-padding medium'><b>Key Features of the Fidweb Intranet Platform:</b></div>",
      "<p className='no-padding'>The newly deployed system includes several integrated modules designed to enhance organizational efficiency:</p>",
      "<li className='bulletpoint'><b>Task Management:</b> Comprehensive tracking system allowing teams to monitor assignments, priorities, and progress in real-time</li>",
      "<li className='bulletpoint'><b>Document Flow:</b> Centralized project repository with activity tracking and file management capabilities</li>",
      "<li className='bulletpoint'><b>Time-Off Management:</b> Streamlined request and approval process for employee absences</li>",
      "<li className='bulletpoint'><b>Calendar and Events:</b> Integrated scheduling tool with company event tracking and birthday reminders</li>",
      "<li className='bulletpoint'><b>Organizational Structure:</b> Company directory with a visual representation of the hierarchy</li>",
      "<li className='bulletpoint'><b>Global Search:</b> Powerful search functionality across the entire platform</li>",
      "<li className='bulletpoint'><b>Internal Messaging:</b> Integrated notification system keeping team members informed of relevant updates</li>",
      "<p className='no-padding medium'>The implementation of Fidweb Intranet at WS Energy marks a significant milestone in the company's digital transformation journey. The platform's intuitive interface and robust functionality have already demonstrated measurable improvements in project completion times and cross-departmental collaboration.</p>",
    ],
  },
  {
    resize: true,
    logo: false,
    image: sportforia,
    title: "Fidweb Launches Revolutionary Sports Highlights Platform",
    html: true,
    id: "7dbd4bf243094f3c9bdf41432789ggwfey",
    // apple: "https://apps.apple.com/bg/app/scoutify-app/id6514315275",
    // play: "https://play.google.com/store/apps/details?id=net.fidweb.scoutify",
    images: [],
    parts: [
      "<p className='no-padding medium'>Sports enthusiasts can now celebrate as Sportforia, the groundbreaking new app designed to revolutionize how fans experience sports highlights, officially launches today across iOS, Android, and web platforms.</p>",
      "<p className='no-padding medium'>Sportforia emerges as the ultimate destination for sports lovers, offering an unparalleled opportunity to re-live cherished sporting moments and follow favorite players, teams, and events through an intuitive and feature-rich platform.</p>",
      "<p className='no-padding medium' style='margin-bottom:5%'><b>A New Era in Sports Entertainment</b></br>Sportforia stands out in the crowded sports media landscape by offering a comprehensive suite of features designed with the modern sports fan in mind:</p>",
      "<li className='bulletpoint'><b>Unlimited Access:</b> Stream sports highlights anytime, anywhere, with no viewing restrictions</li>",
      "<li className='bulletpoint'><b>Instant Streaming:</b> Dive into action-packed moments immediately, with no waiting or buffering</li>",
      "<li className='bulletpoint'><b>Powerful Search:</b> Easily find specific events, players, or teams with an advanced search function</li>",
      "<li className='bulletpoint'><b>Comprehensive Coverage:</b> Enjoy a vast selection of sports, tournaments, and series from around the globe</li>",
      "<li className='bulletpoint'><b>Personalized Notifications:</b> Stay updated with alerts for new uploads in your favorite categories</li>",
      "<li className='bulletpoint'><b>Flexible Viewing Options:</b> Save content to your watchlist or download for offline viewing</li>",
      "<li className='bulletpoint'><b>Social Sharing:</b> Share your favorite videos with friends and family with just a few taps</li>",
      "<li className='bulletpoint'><b>Quality Control:</b> Select your preferred video quality based on your internet connection</li>",
      "<li className='bulletpoint'><b>Subscription Flexibility:</b> Choose from various subscription tiers to fit your viewing habits</li>",
      "<p className='no-padding medium'><b>The Fan Zone: A Social Sports Experiencet</b></br>What truly sets Sportforia apart is its innovative Fan Zone feature, which transforms sports viewing from a passive experience into an interactive social activity. Users can connect with fellow sports enthusiasts, comment on highlighted moments, share personal stories about players and events, and engage in discussions about their favorite sporting memories.</p>",
      "<p className='no-padding medium'><b>Availability and Access</b></br>Sportforia is now available for download on the Apple App Store, Google Play Store, and accessible via web browser. New users can explore all premium features before choosing their preferred subscription plan</p>",
    ],
  },
  {
    image: scoutify,
    title: "Scoutify App: Revolutionising Athlete Talent Tracking",
    html: true,
    id: "7dbd4bf243094f3c9bdf41432789ggwfer",
    apple: "https://apps.apple.com/bg/app/scoutify-app/id6514315275",
    play: "https://play.google.com/store/apps/details?id=net.fidweb.scoutify",
    images: [],
    parts: [
      "<p className='no-padding medium'>We are proud to announce that the next step forward for sports talent management has been done by our team:  we officially release the new mobile app Scoutify - providing sports scouts with a cutting-edge tool to track and manage their talents. This innovative platform is designed to streamline the scouting process, offering a seamless way to record player performance, monitor progress, manage data from anywhere and communicate with colleagues - all within one app.</p>",
      "<div className='flex flex-col no-padding medium'><b>Partnerships with Industry Giants</b></div>",
      "<p className='no-padding medium'>What makes this launch particularly noteworthy is Fidweb’s collaboration with two industry giants: Puma and IMG. These global leaders in sportswear and talent management, respectively, have partnered with us to integrate the Scoutify app into their scouting operations from day one. This partnership not only underscores the app’s potential impact on the industry, but also offers an endorsement of its capabilities.</p>",
      "<div className='flex flex-col no-padding medium'><b>Tailored Features for Modern Scouting</b></div>",
      "<p className='no-padding medium'>Scoutify's features are tailored to meet the needs of modern sports scouts, including real-time data entry, detailed analytics, and easy communication between scouts and management. With the backing of Puma and IMG, the app is set to become an indispensable tool for scouting professionals worldwide, offering a new level of precision and efficiency in tracking athletic talent.</p>",
      "<div className='flex flex-col no-padding medium'><b>Setting a New Standard in Sports Talent Management</b></div>",
      "<p className='no-padding medium'>The launch of Scoutify marks a new era in sports talent management, with Puma and IMG leading the way in adopting this powerful new tool and more industry giants about to follow their footsteps.</p>",
      "<p className='no-padding medium'><b>Scoutify is now live in App Store and Google Play:</b></p>",
    ],
  },
  {
    image: amarant3,
    title: "Amarant App Achieves Stellar Rating on App Store and Google Play Store!",
    html: true,
    logo: amarant,
    id: "7dbd4bf243094f3c9bdf41432789abbbccc",
    parts: [
      "<p className='no-padding medium'>We are excited to share the phenomenal success of our app, Amarant, on App Store and Google Play Store! Our app has garnered an outstanding average rating of 4.9 out of 5 stars from more than 10,000 users on iPhone devices and more than 20,000 users on Android devices!. This incredible rating reflects the dedication of our team and the amazing support from our users.</p>",
      "<div className='flex flex-col no-padding medium'><b>Thank You to Our Incredible Users</b></div>",
      "<p className='no-padding medium'>We want to extend our heartfelt thanks to each and every one of you who has taken the time to rate and review Amarant on App Store and Google Play Store. Your feedback has been invaluable, and your support has made all the difference. Achieving such a high rating is no small feat, and we couldn’t have done it without your trust and enthusiasm.</p>",
      "<div className='flex flex-col no-padding medium'><b>Just the Beginning</b></div>",
      "<p className='no-padding medium'>While we celebrate this significant achievement, we also recognize that this is just the beginning of our journey. We are committed to bringing you even more features, improvements, and innovations to enhance your experience with Amarant. Our team is already working hard on the next updates, and we can’t wait to share what we have in store.</p>",
      "<div className='flex flex-col no-padding medium'><b>Stay Tuned</b></div>",
      "<p className='no-padding medium'>We invite you to stay tuned for exciting updates and new features that will make Amarant even more enjoyable and useful. Our journey is far from over, and with your continued support, the sky’s the limit!</p>",
      "<p className='no-padding medium'>Once again, thank you for being a part of the Amarant community. Together, we are making something truly special.</p>",
    ],
  },
  {
    image: fightScoutBKFC,
    title: "Fight Scout Named Official App for Fighter Recruitment for BKFC",
    html: true,
    logo: false,
    id: "7dbd4bf243094f3c9bdf41432789dxyz",
    parts: [
      "<p className='no-padding medium'>We are thrilled to share some exciting news from the world of combat sports. David Feldman, the President of Bare Knuckle Fighting Championship (BKFC), has officially announced that Fight Scout is the go-to app for fighters aspiring to participate in BKFC events. This significant endorsement underscores the value and reliability of our cutting-edge mobile app, Fight Scout.</p>",
      "<div className='flex flex-col no-padding medium'><b>A New Era for Fighter Recruitment</b>\n<p>As the leading platform for connecting fighters with promoters and events, Fight Scout has revolutionized how fighters navigate their careers.</p></div>",
      "<div className='flex flex-col no-padding medium'><b>Endorsement from BKFC</b>\n<p>David Feldman's announcement marks a significant milestone for Fight Scout. BKFC, known for its intense and thrilling bare-knuckle fights, attracts fighters from around the globe. By endorsing Fight Scout, Feldman has solidified the app's status as an essential tool for fighters aiming to compete at the highest level.</p></div>",
      '<p className="no-padding medium">"If you’re looking to fight for BKFC, download the Fight Scout app now and create your profile" Feldman stated. "That will help us identify the best fighters for the right event. And unlike any other platforms Fight Scout allows you to manage your own profile that will help you create your professional fighting resume"</p>',
      "<div className='flex flex-col no-padding medium'><b>What This Means for Fighters</b>\n<p>For fighters, this endorsement means that using Fight Scout can significantly enhance their chances of being noticed by BKFC promoters. By creating a profile on Fight Scout, fighters can ensure they are visible to the right people in the industry, increasing their chances of securing coveted spots in BKFC events.</p></div>",
      "<div className='flex flex-col no-padding medium'><b>About Fight Scout</b>\n<p>Developed by Fidweb, Fight Scout is designed to empower fighters and promoters in the combat sports industry. Our mission is to provide a user-friendly, efficient, and reliable platform that supports the growth and success of fighters at all levels. The app is available for download on both iOS and Android devices.</p></div>",
      "<div className='flex flex-col no-padding medium'><b>Join the Fight</b>\n<p>Whether you're a fighter looking to make your mark in the BKFC, promoter or a manager, searching for top talent, or just a fan who wants to keep up with current events, Fight Scout is the app you need. Join the Fight Scout community today and take your career to the next level.</p></div>",
      "<div className='flex flex-col no-padding medium'>Check out David Feldman’s announcement here: <a style={{margin-left:'10px'}} href='https://www.instagram.com/reel/C9ksK7-pZFr/?igsh=MTJsOW5uM3RpNHh5bQ=='>Click here!</a></div>",
    ],
  },
  {
    image: fightScoutWebsite,
    title: "FIGHT SCOUT presents its new website",
    html: true,
    logo: false,
    id: "7ebd4bf243094f3c9bdf41565789daem",
    parts: [
      "<p className='no-padding medium'>Step into a realm where the echoes of every punch and the artistry of every move converge – welcome to the newly redesigned Fight Scout website. Here, the digital canvas is transformed into a tapestry of profound insights, offering aficionados a journey deep into the soul of combat sports.</p>",
      "<p className='no-padding medium'><b>Real-time Updates: </b>Fight Scout's website provides users with instantaneous updates, ensuring fans stay informed about the latest events, match outcomes, and developments in the world of combat sports directly through its application.</p>",
      "<p className='no-padding medium'><b>Comprehensive Statistics: </b>The application offers in-depth statistical analysis, providing users with a nuanced understanding of fighters' performances, strengths, and weaknesses, all within the convenience of a dedicated software environment.</p>",
      "<p className='no-padding medium'><b>Exclusive Insights: </b>Fight Scout goes beyond the surface, offering exclusive behind-the-scenes content and insights into the lives of fighters, directly accessible within the application for a more intimate connection with the athletes.</p>",
      "<p className='no-padding medium'><b>User-Friendly Interface: </b>The application is designed with user experience in mind, featuring a clean and intuitive interface that makes navigation seamless. Users can easily access the information they need within the dedicated software environment.</p>",
      "<p className='no-padding medium'><b>Immersive Fan Experience: </b>Fight Scout aims to elevate the fan experience by creating an immersive digital arena within the application. From live updates to interactive features, the software is tailored to engage users and deepen their connection with the world of combat sports.</p>",
      "<p className='no-padding medium'><b>Enhanced Accessibility: </b>As a dedicated software application, Fight Scout ensures accessibility from various devices, allowing users to stay connected with their favorite sports and fighters anytime, anywhere, as long as the application is installed.</p>",
      "<p className='no-padding medium last'><b>Revolutionary Approach: </b> The application represents a cutting-edge approach to how fans interact with combat sports. By incorporating innovative features within its dedicated software environment, Fight Scout stands out as a pioneer in transforming the traditional fan experience.</p>",
    ],
  },
  {
    image: fantastico,
    title: "Unveiling the Partnership with the Fantastico group: Empowering Growth and Knowledge",
    html: true,
    logo: false,
    id: "7ebd4bf243094f3c9bdf41565789daed",
    parts: [
      "<p>It is with great enthusiasm that we announce a transformative collaboration with Fantastico, one of Bulgaria's most prominent supermarket chains.This landmark partnership brings forth a state-of-the-art Learning Management System (LMS), set to revolutionize how Fantastico empowers its workforce and nurtures a culture of continuous learning and growth.</p>",
      "<p className='no-padding'><b>A Nexus of Excellence: Fantastico's Unparalleled Reach and Our Technological Expertise</b><br/>Fantastico’s footprint in the Bulgarian retail landscape is nothing short of impressive. With over 50 locations and a workforce comprising over 5,000 employees, Fantastico is not just a supermarket chain; it’s a vital contributor to the nation’s economy and community. We’ve specialized in creating solutions that transcend industry norms, and now we are happy to be crafting a Learning Management System tailored to Fantastico’s unique needs.</p>",
      "<p className='no-padding'><b>The Learning Management System (LMS) Redefined</b><br/>At the heart of this partnership is an innovative Learning Management System (LMS) that empowers Fantastico's employees with a diverse range of tools and resources. This LMS represents a convergence of learning, communication, and growth, offering features that go beyond conventional learning platforms:</p>",
      "<li className='bulletpoint'><b>News and Events:</b> Stay connected with the latest updates and events, fostering a sense of community and shared purpose among employees.</li>",
      "<li className='bulletpoint'><b>Trainings and Tests:</b> Propel employee development through accessible online training modules and assessment tools, ensuring that learning is continuous and comprehensive.</li>",
      "<li className='bulletpoint'><b>Company Calendar:</b> Seamlessly manage track all events, making time management an effortless endeavor.</li>",
      "<li className='bulletpoint'><b>Chat Functionality:</b> Facilitate real-time communication and collaboration, fostering engagement and enabling quick information sharing.</li>",
      "<li className='bulletpoint'><b>Documents Module:</b> Sign and access essential documents securely, eliminating administrative bottlenecks and enhancing productivity.</li>",
      "<li className='bulletpoint'><b>Career Hub:</b> Empower employees to explore growth opportunities within the Fantastico group, fostering a culture of advancement from within.</li>",
      "<p className='no-padding'><b>A Collaborative Path to Innovation</b></p>",
      "<p>Together we are rewriting the rules of learning, communication, and growth—a path that promises to inspire, empower, and shape a new era of excellence.</p>",
    ],
  },
  {
    image: fightScoutCommunication,
    title: "Elevating Communication in the Digital Age: The All-New FightScout Mobile App",
    html: true,
    logo: fightscout,
    id: "7ebd4bf243094f3c9bdf41565789daef",
    parts: [
      "<p>In today’s hyper-connected world, communication has transcended boundaries, bringing people closer than ever before. Keeping pace with this ever-evolving landscape, the FightScout mobile app has unveiled a monumental update that not only enhances its chat functionality but also introduces a range of features that revolutionize the way we interact digitally. With a focus on visual enhancements, seamless media sharing, advanced scheduling capabilities, and the groundbreaking addition of in-app video calls, FightScout is poised to reshape the way we connect, collaborate, and communicate.</p>",
      "<p className='no-padding'><b>A Visual Symphony: The Power of Visual Enhancements:</b></p>",
      "<p>First impressions matter, and FightScout understands the significance of visually engaging interactions. The new version of the app boasts a refined user interface that’s not just visually appealing, but also intuitive.</p>",
      "<p className='no-padding'><b>The Chat Revolution: Sharing Media, Files, and Voice Messages:</b></p>",
      "<p>The chat experience within the FightScout app has been elevated to new heights. Users can now seamlessly share files and media, whether it's documents, images, or videos, without ever leaving the conversation. This functionality streamlines collaboration, making it an ideal platform for both personal and professional interactions. Moreover, the introduction of voice messages adds a personal touch to digital conversations, enabling users to infuse their personality into every exchange.</p>",
      "<p className='no-padding'><b>Tracking Communication: Delivered and Read Receipts:</b></p>",
      "<p>Transparent communication is at the heart of effective interaction. FightScout acknowledges this need by introducing delivered and read receipts. Users can now effortlessly track when their messages have been delivered and subsequently read, ensuring that they're always informed about the status of their conversations.</p>",
      "<p className='no-padding'><b>Scheduling Redefined: The Video Call Evolution:</b></p>",
      "<p>The crown jewel of the FightScout update is the introduction of in-app video calls, paired with an advanced scheduling system. Users can now schedule video calls with ease, ensuring that they can connect at a time that suits their busy lives. This revolutionary feature is designed to accommodate both personal and professional needs, fostering seamless communication no matter the context. With the ability to schedule as many video calls as desired, FightScout empowers users to engage in meaningful conversations on their terms.</p>",
      "<p className='no-padding'><b>Embracing the Future of Communication:</b></p>",
      "<p>The FightScout mobile app's latest update ushers in a new era of communication, where boundaries are shattered, and connectivity knows no limits. By seamlessly integrating visual enhancements, media sharing, voice messages, and the innovative in-app video call feature, FightScout has emerged as a comprehensive communication hub that caters to the diverse needs of its users.</p>",
    ],
  },
  {
    image: winkUpdate,
    title: "Wink Mobile App’s Game-Changing Payment Update",
    html: true,
    logo: wink,
    id: "7ebd4bf243094f3c9bdf41565789daeg",
    parts: [
      "<p>In the ever-evolving landscape of sustainable transportation, the Wink mobile app stands offers an all-in-one solution for electric vehicle (EV) charging. With a steadfast commitment to enhancing user experience and convenience, Wink is thrilled to announce a big update that is set to redefine how EV owners pay for their charging sessions. The app's new payment system, centered around a virtual wallet, simplifies the process, streamlines transactions, and brings a new level of ease to the world of electric vehicle charging.</p>",
      "<p className='no-padding'><b>A Seamless Transition to the Future of Payments</b></p>",
      "<p>The heart of this update lies in Wink's innovative approach to payments. By introducing a virtual wallet, Wink offers users an incredibly convenient and efficient method for managing their charging expenses. No more fumbling for credit cards or worrying about entering payment details every time a charging session is initiated. With the virtual wallet, users can pre-load funds and then make hassle-free payments directly from their wallet within the app.</p>",
      "<p className='no-padding'><b>The Power of the Virtual Wallet</b></p>",
      "<p>The virtual wallet feature is designed to empower EV owners with unparalleled control over their charging experiences. Users can easily add funds to their wallet using a variety of payment methods, ensuring flexibility and accessibility. Once funds are loaded, they are readily available to cover charging expenses whenever needed. This eliminates the need for constant payment authorization and streamlines the entire process, making EV charging even more user-friendly.</p>",
      "<p className='no-padding'><b>A Step Towards a Sustainable Future</b></p>",
      "<p>The Wink mobile app's dedication to sustainability is seamlessly integrated into this new payment system. By simplifying payments and enhancing user experience, Wink encourages more individuals to embrace electric vehicles as a greener alternative to traditional transportation. As EV adoption grows, Wink's commitment to innovation is set to drive a positive impact on the environment and pave the way for a more sustainable future.</p>",
    ],
  },
  {
    image: testMyStress,
    title: "Introducing Practical Stress: Evolutionizing Psychological Consultations and Stress Measurement Cutting-Edge Platform",
    html: true,
    logo: testMyStressLogo,
    id: "7ebd4bf243094f3c9bdf41565789daeg",
    parts: [
      "<p>In today's fast-paced world, where mental well-being is of paramount importance, a groundbreaking solution has emerged to make psychological consultations, events, and stress measurement more accessible and effective than ever before. Enter Practical Stress, a state-of-the-art web platform designed to cater to individuals seeking professional psychological support, organizing psychology-related events, and even evaluating their stress levels through custom tests. With a user-friendly interface and a comprehensive admin panel, Practical Stress offers a seamless experience for both clients and administrators.</p>",
      "<p className='no-padding'><b>Empowering Mental Well-being Through Psychological Consultations:</b></p>",
      "<p>MindWell offers a virtual space where individuals can connect with licensed psychologists and therapists from the comfort of their own homes or book an in-face consultation directly through the platform. With an emphasis on privacy, security, and convenience, the platform ensures that users can schedule sessions that fit their busy lives. Whether users are seeking guidance for stress, anxiety, depression, or any other mental health concern, Practical Stress’'s carefully curated pool of professionals ensures that clients find the right match for their needs.</p>",
      "<p className='no-padding'><b>Innovative Psychology Events and Workshops:</b></p>",
      "<p>The platform goes beyond one-on-one consultations by hosting a diverse range of psychology-related events and workshops. From expert-led seminars on stress management and mindfulness to group therapy sessions, the platform brings the global psychology community closer together.</p>",
      "<p className='no-padding'><b>Custom Stress Level Measurement Tests:</b></p>",
      "<p>One of Practical Stess’'s standout features is its ability to provide users with customized stress level measurement tests. These tests are designed to assess an individual's stress levels and provide insights into their mental well-being. Users can take these tests at their convenience, and the platform's advanced algorithms analyze the results to generate personalized recommendations for stress reduction techniques and coping strategies. This unique feature empowers users to take proactive steps towards improving their mental health.</p>",
      "<p className='no-padding'><b>Empowering Clients with a Custom Admin Panel:</b></p>",
      "<p>We’ve built an intuitive admin panel that puts the client in control of their platform. This panel allows administrators to manage appointments, events, and tests effortlessly. With the ability to customize the content and user experience, administrators can tailor Practical Stress to align with their organization's identity and goals.</p>",
      "<p className='no-padding'><b>Ensuring Privacy and Security:</b></p>",
      "<p>We all understand the sensitivity of the information shared during psychological consultations and stress measurement tests. The platform places the utmost importance on privacy and security, employing robust encryption and stringent data protection measures.</p>",
      "<p className='no-padding'><b>A New Era of Mental Well-being</b></p>",
      "<p>Practical Stress represents a revolutionary step forward in the field of psychological consultations, events, and stress measurement. By offering a versatile and user-friendly platform, it empowers individuals to prioritize their mental well-being conveniently. As the world continues to acknowledge the importance of mental health, Practical Stress stands as a beacon of hope, fostering a new era of accessible, effective, and comprehensive mental well-being support.</p>",
    ],
  },
  {
    image: mobiWash,
    title: "MobiWash: Revolutionizing Car Washing with Convenience at Your Fingertips",
    html: true,
    logo: mobiWashLogo,
    id: "7ebd4bf243094f3c9bdf41565789daeh",
    parts: [
      "<p>In the fast-paced world we live in, convenience and efficiency have become paramount in every aspect of our lives. From food delivery to ride-sharing, technology has transformed the way we access services. Now, a new mobile app is taking convenience to the next level when it comes to car washing. <b>MobiWash</b> is poised to revolutionize the car washing industry by offering users the ability to book mobile car washing services right to their doorstep or at a preferred location, all through the power of a mobile app.</p>",
      "<p className='no-padding'><b>The Evolution of Car Washing:</b></p>",
      "<p>Gone are the days of waiting in long lines at traditional car wash stations or taking time out of a busy schedule to get your vehicle cleaned. MobiWash steps in to provide a modern solution that makes car washing effortless. As an extension of the Amarant mobile app, MobiWash leverages cutting-edge technology to connect car owners with professional car washers, creating a seamless experience from start to finish.</p>",
      "<p className='no-padding'><b>Unmatched Convenience with On-Demand Service:</b></p>",
      "<p>MobiWash empowers users to book a car wash at their preferred location, whether it's at their home, office, or any other suitable spot. The app offers a simple and intuitive interface, allowing users to choose from a range of available services and select a convenient time slot. This eliminates the hassle of driving to a car wash facility, saving valuable time and effort.</p>",
      "<p className='no-padding'><b>Tracking and Transparency:</b></p>",
      "<p>One of the standout features of MobiWash is its ability to provide users with real-time tracking of the car washing process. With the app, users can track the location of the service provider, get notified when they are on the way, and monitor the progress of the cleaning process. This transparency ensures that users are always in the loop and can plan their schedule accordingly</p>",
      "<p className='no-padding'><b>Seamless Payment and Communication:</b></p>",
      "<p>MobiWash takes convenience a step further by allowing users to pay for their car washing services directly through the app. This means no more fumbling for cash or dealing with payment terminals. The app also features a direct chat function, enabling users to communicate with MobiWash service providers without the need for any external communication channels</p>",
      "<p className='no-padding'><b>Order History and Management:</b></p>",
      "<p>The app offers a comprehensive overview of the user's order history, making it easy to keep track of past car washing sessions. Whether it's a quick exterior clean or a more detailed interior and exterior service, users can review their orders and preferences, ensuring consistent service every time.</p>",
      "<p className='no-padding'><b>A Sustainable Approach:</b></p>",
      "<p>MobiWash not only provides convenience but also contributes to environmental sustainability. By offering water-efficient car washing techniques and products, the app aligns with eco-friendly practices, reducing water wastage and environmental impact.</p>",
      "<p className='no-padding'><b>A Bright Future for Car Washing</b></p>",
      "<p>MobiWash is not just an app; it's a game-changer for the car washing industry. By combining convenience, transparency, and efficient communication, the app reshapes how car owners approach vehicle maintenance. As the app gains traction and continues to evolve, it's clear that MobiWash is paving the way for a future where convenience and modern technology go hand in hand, enhancing the car washing experience for everyone. Say goodbye to car wash woes and hello to MobiWash!</p>",
    ],
  },
  {
    image: immotechGermany,
    title: "Immotech App’s Major Partnership Set to Redefine the German Tech Scene",
    html: true,
    logo: immotech,
    id: "7ebd4bf243094f3c9bdf41565789dael",
    parts: [
      "<p>With an unwavering commitment to providing cutting-edge solutions, Immotech App is gearing up for an exciting journey of transformation as it readies to introduce its revolutionary platform to the German market this autumn.</p>",
      "<p className='no-padding'><b>Unlocking a New Era of Real Estate Management</b></p>",
      "<p>As Immotech App prepares to set its foot in Germany, the company is fully immersed in tailoring its platform to meet the specific needs and preferences of the German market. This strategic alignment ensures that the platform seamlessly integrates with the country's real estate ecosystem, delivering a unique blend of efficiency, innovation, and user-centricity.</p>",
      "<p className='no-padding'><b>The Neighbours Module: A Game-Changing Update</b></p>",
      "<p>One of the most anticipated updates comes in the form of an enhanced `neighbors module`. This update signifies a deeper understanding of the German real estate ethos, where community and interaction are held in high regard. Immotech App's `neighbors module` will facilitate communication and collaboration among residents, fostering a sense of belonging and shared responsibility within the community. This module is set to transform how neighbors interact, creating a platform for discussions, announcements, and mutual support, thus cultivating a stronger sense of unity.</p>",
      "<p className='no-padding'><b>Conclusion: A Bold Step into the German Future of Real Estate</b></p>",
      "<p>The Immotech App's forthcoming launch in Germany is much more than a mere expansion—it's a testament to the company's visionary approach to real estate management. The German market is about to witness a transformation that will bring unprecedented convenience, engagement, and efficiency to the world of real estate. As Immotech App steps onto the German stage this autumn, it brings with it a promise of elevated living experiences, stronger communities, and a paradigm shift in real estate management.</p>",
    ],
  },
  {
    image: enrpi,
    title: "Empowering Efficiency: EnRPI Welcomes V&K Energy as New Client",
    html: true,
    logo: false,
    id: "7ebd4bf243094f3c9bdf41565789daem",
    parts: [
      "<p>In the realm of energy management and resource optimisation, EnRPI has been a stalwart, offering cutting-edge solutions that streamline processes and enhance efficiency. The latest addition to EnRPI’s portfolio of esteemed clients is V&K Energy, a pioneering force in Bulgaria’s energy landscape. V&K Energy’s role in supplying electricity to all V&K companies specialising in water and canalisation services makes them a vital player in the nation’s infrastructure. With EnRPI taking on the responsibility of maintaining V&K Energy’s operational processes, a new era of optimised management and enhanced operations is set to unfold.</p>",
      "<p className='no-padding'><b>A Strategic Partnership for Streamlined Operations:</b></p>",
      "<p>The partnership between EnRPI and V&K Energy is built upon a foundation of innovation and mutual benefit. EnRPI's web application has earned a reputation for being an industry leader in process optimisation, resource allocation, and energy management. Now, with V&K Energy onboard, these capabilities will be harnessed to ensure that the supply of electricity to V&K's water and canalisation companies is not only uninterrupted but also optimized for efficiency.</p>",
      "<p className='no-padding'><b>EnRPI's Role in Elevating V&K Energy's Operations:</b></p>",
      "<p>EnRPI's web application is designed to provide comprehensive control over a range of operational processes, allowing for real-time monitoring, data analysis, and strategic decision-making. With V&K Energy's crucial role in supplying electricity to various V&K companies, the need for efficient management becomes paramount.</p>",
      "<p className='no-padding'><b>A Dynamic Partnership for Excellence:</b></p>",
      "<p>The union of EnRPI and V&K Energy is a testament to the power of technology-driven partnerships. As V&K Energy continues its vital role in ensuring electricity supply to water and canalisation companies across Bulgaria, EnRPI's expertise will serve as the cornerstone of operational excellence. The synergy between these two forces promises to elevate efficiency, drive informed decision-making, and contribute to a sustainable energy landscape.</p>",
    ],
  },
  {
    image: winkNews,
    title: "Wink Mobile App: Earning Recognition and Making Waves in Forbes and Kapital Magazines",
    html: true,
    logo: wink,
    id: "7ebd4bf243094f3c9bdf41565789daen",
    parts: [
      "<p>In the dynamic landscape of mobile applications, the Wink app has emerged as a trailblazer in the field of electric vehicle (EV) charging solutions. With a steadfast commitment to innovation and sustainability, Wink has captured the attention of industry leaders and media alike, earning notable features in prestigious publications such as Forbes and Kapital magazines.</p>",
      "<p className='no-padding'><b>Forbes Acknowledges Wink’s Innovation</b></p>",
      "<p>Forbes, renowned for its coverage of groundbreaking technologies and innovative solutions, has taken notice of the Wink mobile app’s exceptional contributions to the realm of sustainable transportation. The feature highlights Wink’s commitment to streamlining the EV charging experience and emphasises its role in shaping the future of mobility.</p>",
      "<p className='no-padding'><b>Kapital Magazine Highlights Wink’s Industry Leadership</b></p>",
      "<p className='no-margin'>Kapital Magazine, a prominent source of business and economic news, has also recognized the Wink mobile app’s impact within the electric vehicle charging sector. The feature in Kapital sheds light on how Wink’s user-centric approach to EV charging addresses the needs of both consumers and the environment. By offering an all-in-one solution that enhances convenience and encourages sustainability, Wink is setting new standards in the industry.</p>",
    ],
    images: [
      { logo: "forbes", link: "https://forbesbulgaria.com/2023/08/10/%d1%81%d1%8a%d1%81-%d0%b7%d0%b0%d1%80%d1%8f%d0%b4-%d0%bd%d0%b0-%d0%ba%d0%b0%d1%83%d0%b7%d0%b0/" },
      { logo: "kapital", link: "https://www.capital.bg/biznes/transport/2023/07/28/4510501_mnogo_ili_malko_sa_1300_zariadni_stancii_v_bulgariia/" },
    ],
  },
  {
    image: alpi,
    title: "ALPI's Remarkable Journey: Featured in Forbes Magazine",
    html: true,
    logo: alpiLogo,
    id: "7ebd4bf243094f3c9bdf41565789daeo",
    parts: [
      "<p>In the dynamic realm of online commerce, where innovation and conscious consumerism converge, ALPI has emerged as a pioneering force, captivating the attention of industry insiders and garnering recognition from esteemed publications such as Forbes Magazine. As a platform dedicated to offering its own line of bio products, ALPI's journey towards sustainability, authenticity, and innovation has caught the eye of Forbes, underscoring the company's transformative impact on the online shopping landscape.</p>",
      "<p className='no-padding'><b>Forbes Magazine Sheds Light on ALPI's Vision</b></p>",
      "<p>Forbes Magazine, known for its insightful coverage of businesses that are redefining industries, has turned its spotlight on ALPI's remarkable journey. The feature encapsulates ALPI's dedication to creating an online shopping platform that goes beyond mere transactions. Instead, ALPI is focused on building a platform that fosters conscious consumer choices, sustainability, and a deeper connection between shoppers and the products they purchase. At the core of ALPI's ethos lies a commitment to delivering bio products that align with consumers' desire for authenticity and well-being.</p>",
    ],
    images: [{ logo: "forbes", link: "https://forbesbulgaria.com/2023/08/04/%d0%b3%d0%bb%d0%be%d0%b1%d0%b0%d0%bb%d0%b5%d0%bd-%d0%b1%d0%b8%d0%b7%d0%bd%d0%b5%d1%81-%d0%b2-amazon-%d0%be%d1%82-%d1%86%d0%b5%d0%bd%d1%82%d1%8a%d1%80%d0%b0-%d0%bd%d0%b0-%d1%81%d0%be%d1%84%d0%b8%d1%8f/" }],
  },
  {
    image: testMyStressNew,
    title: "New project Test My Stress",
    html: true,
    logo: testMyStressLogoNew,
    id: "7ebd4bf243094f3c9bdf41565789dauf",
    parts: [
      "<p>With the new financial possibilities you can easily plan your monthly budget, receive and pay invoices to different providers, and last, but not least, receive advices how to optimise your expenses, thanks to automatically generated financial analysis.</p>",
      "<p>And that’s not all! With the brand new section “Neighbours”, you can easily get in touch with your community to discuss important issues, create polls and even pay your maintenance fees directly from your phone!</p>",
      "<p>Yes, it’s impressive… Try it now! More than 20 000 already did!</p>",
    ],
  },
  {
    image: immotechGermany,
    title: "Immotech launching soon in Germany",
    html: true,
    logo: immotech,
    id: "7ebd4bf243094f3c9bdf41565789dajk",
    parts: [
      "<p>With the new financial possibilities you can easily plan your monthly budget, receive and pay invoices to different providers, and last, but not least, receive advices how to optimise your expenses, thanks to automatically generated financial analysis.</p>",
      "<p>And that’s not all! With the brand new section “Neighbours”, you can easily get in touch with your community to discuss important issues, create polls and even pay your maintenance fees directly from your phone!</p>",
      "<p>Yes, it’s impressive… Try it now! More than 20 000 already did!</p>",
    ],
  },
  {
    title: "Честит Национален празник, българи!",
    image: HolidayPicture,
    html: true,
    id: "51fb1943fhfh411091f459e0c19041bc",
    parts: [
      "<p>Свобода, сила и прогрес - всичко това олицетворява Българският Национален Празник. Бъдете горди българи! Поздравяваме всички свои сънародници с настъпването на Националния празник!</p>",
      "<p>На 3-ти Март 2023г. се честват 145 години от подписване на Сан Стефанския мирен договор, който се явява идеала на българския народ за очертаване на границите, в които живеят етнически българи. Всички ние сме вечно признателни на предците си, жертвали себе си в името на най-ценното човешко право - да бъде свободен!</p>",
      "<p>Да живее България!</p>",
    ],
  },
  {
    title: "New Partnership Announced with Social Media Platform FightScout",
    image: fightScoutCombine,
    html: true,
    id: "51fb1943fdjv411091f459e0c19041cd",
    parts: [
      "<p>The Global Association of MMA - GAMMA - has announced a partnership with the dedicated social media platform for the combat sports community - FightScout.</p>",
      "<p>GAMMA has decided to create this partnership to bring additional opportunities to its community to grow their networks, promote their activities, market events, and potentially generate revenue using the platform to create and monetize content.</p>",
      "<p>Announcing the partnership, President of GAMMA Alexander Engelhardt said, “we are always looking at innovative ways to support our community and to find ways to stay connected while showcasing the incredible value of our sport. We have all experienced the power and reach of LinkedIn and we are excited to be part of this new journey with FightScout for the future of our sport.”</p>",
      "<p>For more information click <a style='color:#1F33CF, fontWeight: `700px`' href='https://gamma-sport.org/2023/02/13/new-partnership-announced-with-social-media-platform-fightscout/'>here.</a></p>",
    ],
  },
  {
    title: "EnRPi welcomes Proakt",
    image: proakt,
    html: true,
    id: "b2454545454549e13437594134134df4",
    parts: [
      "<p>We are happy to welcome our newest partner Proakt!</p>",
      "<p>As one of the leaders in the electricity services market, Proakt decided to use our in-house built and managed software product EnRPi, which makes us very proud!</p>",
      "<p>From its release less than a year ago, numerous leading companies in the energy sector opted for joining EnRPi.</p>",
      "<p>We wish Proakt great success and long and fruitful partnership with us!</p>",
    ],
  },
  {
    title: "New Year, new amazing project completed!",
    image: gfl,
    html: true,
    id: "b198c0ab807049e134375941341dfdf4",
    parts: [
      "<p>We are proud to present to you our latest project “Golden Flight Level”. GFL is the annual international winter sports championship of air traffic controllers with participants from all across Europe, as well as from overseas (Canada, USA, Carribbean, Russia, Dubai and Australia).</p>",
      "<p>Today GFL includes skiing, snowboard and cross country races, as well as spectacular accompanying events, including an opening ceremony, a theme party and a prize giving ceremony with gala dinner at the end of the week.</p>",
      "<p>In 2024 the 49th edition of Golden Flight Level will take place in Bansko in Bulgaria from March 16th to March 23rd.</p>",
      "<p>We as a company were appointed by the Bulgarian hosts to prepare the website, registration and accommodation form, and also to provide the platform for the official results. It is a honor to be part of such a big international event.</p>",
      "<p>We are convinced that BULATSA and Bansko will host this event brilliantly!</p>",
    ],
  },
  {
    title: "Fight Scout raises 50 000$ pre-seed funding!",
    image: fightScoutNew,
    html: true,
    id: "b198c0ab807049e48c78d2433bdd1f0e",
    parts: [
      "<p>It’s no wonder that Fight Scout received 50 000$ pre-seed funding to scale its app. Now it’s the “time to shine” moment for the team behind the Fight Scout, which allows users from all over the world to find and connect with other members of the combat sport community. Whether you’re a seasoned fighter or just starting out, Fight Scout makes it easy to arrange a fight or find the right person to manage your career.</p>",
      "<p>The round was led by Vitosha Venture Partners, who invested at an undisclosed valuation. Although it is growing rapidly, with the raised amount, the founder and CEO Mr. Lubomir Guedjev can afford to meet all of the demands and requests of the international market.</p>",
      "<p>We as a team are very proud for developing Fights Scout and are looking forward to make it the most popular app in the world of combat sport community! We’re raising the guard and preparing to attack!</p>",
    ],
  },
  {
    title: "Thank you for a jointly successful 2022!",
    image: HolidaysNews,
    html: true,
    special: true,
    id: "b198c0ab807049e48c78d2503bdd1f0e",
    parts: [
      "<p>We've turned <b>IMMOTECH</b> into a super-app with tons of features for managing your property and household. At the FIABCI World Conference in Paris, we presented the platform for the first time on an international stage and announced its entry into Western Europe. We would also like to thank FORBES Bulgaria for the <a href='https://forbesbulgaria.com/2022/12/07/immotech-the-mobile-application-for-property-and-household-management/'>publication and evaluation</a> in this month's issue of the magazine.</p>",
      "<p>In less than 10 months, we've made <b>FIGHT SCOUT</b> into a favorite platform for athletes, coaches, organizers and fans in over 70 countries around the world - from Brazil to Japan; from Iceland to New Zealand. We are also grateful to the Venture Capital funds supporting the huge potential of this product.</p>",
      "<p>For the internal organization of complex business processes in the energy industry, we developed <b>EnRPi</b> for electricity traders and producers. Analyzing tons of information to make precise calculations in real time allows large corporations to make quick decisions and actions.</p>",
      "<p>Our experience in the automotive world translates into our unique products for this sector. <b>AMARANT</b> got a whole new look and a bunch of new features to consolidate its position as the most successful mobile app in Bulgaria! The highest possible score from tens of thousands of users led both Apple and Google to award us this prestigious prize.</p>",
      "<p>The <b>Wink</b> Charging platform continues the electric revolution in the automotive world - modern charging stations for e-cars throughout Bulgaria, managed through the <b>Wink</b> mobile app.</p>",
      "<p>And the habits of thousands of consumers and companies like BMW and Porsche of keeping their cars clean and roadworthy haven been transformed in no time thanks to the <b>GeoWash</b> innovation.</p>",
      "<p>The results described above commit us in the new year 2023 to continue our progress with a guarantee of professionalism.</p>",
    ],
  },
  {
    title: "Immotech with a BIG update! The new 3.0 version of the popular app gives even more to its users",
    image: immotechNews,
    logo: immotech,
    id: "51fb193f85d2411091f499e0c19041cd",
    parts: [
      "With the new financial possibilities you can easily plan your monthly budget, receive and pay invoices to different providers, and last, but not least, receive advices how to optimise your expenses, thanks to automatically generated financial analysis.",
      "And that’s not all! With the brand new section “Neighbours”, you can easily get in touch with your community to discuss important issues, create polls and even pay your maintenance fees directly from your phone!",
      "Yes, it’s impressive… Try it now! More than 20 000 already did!",
    ],
    apple: "https://apps.apple.com/bg/app/immotech/id1467332873",
    play: "https://play.google.com/store/apps/details?id=com.socourt.immotech",
    site: "https://www.immotech-app.com/",
  },
  {
    title: "Amarant app with more than 10 thousand reviews on Google Play and best possible rating",
    image: amarantNews,
    id: "856dc7809e174a2bbe7409613c814635",
    logo: amarant,
    parts: [
      "Our customer base is growing - many thanks to all users and premium subscribers! But growing is not our ultimate goal - keeping our customers happy is what we are striving for! That is why we are so happy to see Amarant app is keeping its best rating (4,9 of 5 possible stars) by reaching 10k user reviews solely on Google Play.",
      "We keep working hard on making products people really love!",
    ],
    apple: "https://apps.apple.com/us/app/amarant-bulgaria/id1506724182",
    play: "https://play.google.com/store/apps/details?id=com.amarantapp&hl&pli=1",
    site: "https://www.amarantbg.com/",
  },
  {
    image: "https://immotech-web.s3.eu-central-1.amazonaws.com/FidWeb/1.png",
    title: "FIGHT SCOUT is now available",
    logo: fightscout,
    id: "7ebd4bf243094f3c9bdf41565789daec",
    parts: [
      "On the New Year's Eve, we told you about the upcoming launch of this new platform. Now it's a fact - FightScout already enjoys huge approval among athletes, coaches, sports managers and organizers of combat sports events.",
      "The innovative and easy-to-use mobile application proudly bears the label `Made & Supported by Fidweb`.",
      "Learn more on the website, download on your iPhone or Android, and see the new technology in the world of combat sports.",
    ],
    video: "https://www.youtube.com/watch?v=jPBdUZO7434",
    apple: "https://apps.apple.com/bg/app/fightscout/id1590863097?fbclid=IwAR13u_6XIomgZqkcJJuSPiXTrcSVrW94ALkVS2MOMTGHJl9ohpIIVpsKQ2U",
    play: "https://play.google.com/store/apps/details?id=com.socourt.combater",
    site: "https://www.fightscout.app/",
  },
];

export default News;
