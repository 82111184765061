/* eslint-disable */
import React, { useState } from "react";
import { connect } from "react-redux";
import { history } from "../../config/stores";
import { setCurrentStatus } from "../../actions";
import "./styles.scss";
// import Presentation from "../../files/Fidweb-Presentation.pdf";
import Presentation from "../../files/FidWeb-Company-Presentation-2023.pdf";

function MobileHeader({ currentStatus, setCurrentStatus }) {
  const [opened, setOpened] = useState(false);

  return (
    <div className="mobile-header-container">
      <div className="unopened-header">
        <div
          className="logo"
          onClick={() => {
            setCurrentStatus("home");
            history.push("/");
          }}
        />
        <div
          className="menu-icon"
          onClick={() => {
            setOpened(true);
            document.getElementsByTagName("BODY")[0].style.overflow = "hidden";
          }}
        />
      </div>
      {opened && (
        <div className="opened-header">
          <div className="upper-container">
            <div
              className="close-btn"
              onClick={() => {
                setOpened(false);
                document.getElementsByTagName("BODY")[0].style.overflow = "scroll";
              }}
            />
            <div
              className={`menu-element ${currentStatus === "home" && window.location.href.split("/")[3].length < 2 ? "selected" : null}`}
              onClick={() => {
                setCurrentStatus("home");
                history.push("/");
                setOpened(false);
                document.getElementsByTagName("BODY")[0].style.overflow = "scroll";
              }}
            >
              Home
            </div>
            <div className="menu-element" onClick={() => window.open(Presentation)}>
              Portfolio
            </div>
            <div
              className={`menu-element ${currentStatus === "form" && window.location.href.split("/")[3].length < 2 ? "selected" : null}`}
              onClick={() => {
                setOpened(false);
                setCurrentStatus("form");
                history.push("/");
              }}
            >
              Contact us
            </div>
            <div
              className={`menu-element ${window.location.href.split("/")[3] === "news" && "selected"}`}
              onClick={() => {
                setOpened(false);
                history.push("/news");
              }}
            >
              News
            </div>
          </div>
          <div className="lower-overlay"></div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  currentStatus: state.stateControl.currentStatus,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentStatus: (payload) => dispatch(setCurrentStatus(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileHeader);
