export const StateTypes = {
  SET_HEADER_STATUS: "header/SET_HEADER_STATUS",
  SET_CURRENT_STATUS: "header/SET_CURRENT_STATUS",
  SET_SELECTED_ARTICLE: "header/SET_SELECTED_ARTICLE",

  SEND_EMAIL: "header/SEND_EMAIL",
};

export const setHeaderStatus = (payload) => ({
  type: StateTypes.SET_HEADER_STATUS,
  payload,
});

export const setCurrentStatus = (payload) => ({
  type: StateTypes.SET_CURRENT_STATUS,
  payload,
});

export const setSelectedArticle = (payload) => ({
  type: StateTypes.SET_SELECTED_ARTICLE,
  payload,
});

export const sendEmail = (payload) => ({
  type: StateTypes.SEND_EMAIL,
  payload,
});