import { StateTypes } from "../actions";

const initialState = {
  headerStatus: "Software Development",
  currentStatus: "home",
  selectedArticle: {},
};

const stateControl = (state = initialState, { type, payload }) => {
  switch (type) {
    case StateTypes.SET_HEADER_STATUS:
      return { ...state, headerStatus: payload };
    case StateTypes.SET_CURRENT_STATUS:
      return { ...state, currentStatus: payload };
    case StateTypes.SET_SELECTED_ARTICLE:
      return { ...state, selectedArticle: payload };
    default:
      return state;
  }
};

export default stateControl;
