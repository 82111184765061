import { combineReducers } from "redux";
// import { createNavigationReducer } from 'react-navigation-redux-helpers'
import { routerReducer } from "react-router-redux";

import starter from "./starter";
import stateControl from "./stateControl";

export default combineReducers({
  starter,
  routerReducer,
  stateControl,
});
